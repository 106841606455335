require("./bootstrap");
require("./timeline");

$(function () {
  require("./cart");

  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  var slideWrapper = $(".main-slider"),
    iframes = slideWrapper.find(".embed-player"),
    lazyImages = slideWrapper.find(".slide-image"),
    lazyCounter = 0;

  // POST commands to YouTube or Vimeo API
  function postMessageToPlayer(player, command) {
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  }

  // When the slide is changing
  function playPauseVideo(slick, control) {
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");
    slideType = currentSlide.attr("class").split(" ")[1];
    player = currentSlide.find("iframe").get(0);
    startTime = currentSlide.data("video-start");

    if (slideType === "vimeo") {
      switch (control) {
        case "play":
          if (
            startTime != null &&
            startTime > 0 &&
            !currentSlide.hasClass("started")
          ) {
            currentSlide.addClass("started");
            postMessageToPlayer(player, {
              method: "setCurrentTime",
              value: startTime,
            });
          }
          postMessageToPlayer(player, {
            method: "play",
            value: 1,
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            method: "pause",
            value: 1,
          });
          break;
      }
    } else if (slideType === "youtube") {
      switch (control) {
        case "play":
          postMessageToPlayer(player, {
            event: "command",
            func: "mute",
          });
          postMessageToPlayer(player, {
            event: "command",
            func: "playVideo",
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            event: "command",
            func: "pauseVideo",
          });
          break;
      }
    } else if (slideType === "video") {
      video = currentSlide.children("video").get(0);
      if (video != null) {
        if (control === "play") {
          video.play();
        } else {
          video.pause();
        }
      }
    }
  }

  // Resize player
  function resizePlayer(iframes, ratio) {
    if (!iframes[0]) return;
    var win = $(".main-slider"),
      width = win.width(),
      playerWidth,
      height = win.height(),
      playerHeight,
      ratio = ratio || 16 / 9;

    iframes.each(function () {
      var current = $(this);
      if (width / ratio < height) {
        playerWidth = Math.ceil(height * ratio);
        current
          .width(playerWidth)
          .height(height)
          .css({
            left: (width - playerWidth) / 2,
            top: 0,
          });
      } else {
        playerHeight = Math.ceil(width / ratio);
        current
          .width(width)
          .height(playerHeight)
          .css({
            left: 0,
            top: (height - playerHeight) / 2,
          });
      }
    });
  }

  // DOM Ready
  $(function () {
    // Initialize
    slideWrapper.on("init", function (slick) {
      slick = $(slick.currentTarget);
      setTimeout(function () {
        playPauseVideo(slick, "play");
      }, 1000);
      resizePlayer(iframes, 16 / 9);
    });
    slideWrapper.on("beforeChange", function (event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick, "pause");
    });
    slideWrapper.on("afterChange", function (event, slick) {
      slick = $(slick.$slider);
      playPauseVideo(slick, "play");
    });
    slideWrapper.on("lazyLoaded", function (event, slick, image, imageSource) {
      lazyCounter++;
      if (lazyCounter === lazyImages.length) {
        lazyImages.addClass("show");
        // slideWrapper.slick("slickPlay");
      }
    });

    //start the slider
    slideWrapper.slick({
      fade: true,
      autoplaySpeed: 4000,
      autoplay: true,
      lazyLoad: "progressive",
      speed: 1000,
      arrows: true,
      dots: true,
      cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    });
  });

  // Resize event
  $(window).on("resize.slickVideoPlayer", function () {
    resizePlayer(iframes, 16 / 9);
  });

  // Positive message timeout
  setTimeout(function () {
    $(".message.positive").animate(
      {
        height: "toggle",
        opacity: "toggle",
      },
      500
    );
  }, 5000);

  // Accordion

  $(".toggle .toggle-title")
    .not(".active")
    .click(function () {
      if (!$(this).closest(".toggle").hasClass("active")) {
        $(".toggle.active")
          .removeClass("active")
          .find(".toggle-inner")
          .slideUp(200);
        $(this)
          .closest(".toggle")
          .toggleClass("active")
          .find(".toggle-inner")
          .slideToggle(200);
      }
    });
  initializeFlatPickr();
  var slideWrapper = $(".main-slider"),
    iframes = slideWrapper.find(".embed-player"),
    lazyImages = slideWrapper.find(".slide-image"),
    lazyCounter = 0;

  // Default Slick Slider
  $(".cms-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    fade: true,
    cssEase: "linear",
    autoplay: false,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  });

  $(".timeline-cms-slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: true,
    fade: true,
    cssEase: "ease",
    autoplay: false,
    customPaging: function (slider, i) {
      var slidernav = $(slider.$slides[i]).data("slidernav");
      return "<a>" + slidernav + "</a>";
    },
  });

  // Convert .svg image to SVG format (add class="svg")
  $("img.svg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(
      imgURL,
      function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find("svg");

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }

        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr("xmlns:a");

        // Replace image with new SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });

  $("select.selectize").each(function () {
    var delimiter = ",";
    var persist = false;
    var openOnFocus = true;
    var sortField = "value";

    if ($(this).hasClass("sort-index")) {
      sortField = [
        {
          field: "index",
          direction: "desc",
        },
        {
          field: "$score",
        },
      ];
    }

    $(this).selectize({
      delimiter: delimiter,
      persist: persist,
      sortField: sortField,
      openOnFocus: openOnFocus,
    });
  });
});

function thisURL(params) {
  var url,
    thisURL = document.location.pathname;

  if (params === 1) {
    // option = 1 = full page name with all parameters eg: products.php?id_product=1
    thisURLwithParam = thisURL.substring(
      0,
      thisURL.indexOf("#") == -1 ? thisURL.length : thisURL.indexOf("#")
    );
    thisURLwithParam = thisURLwithParam.substring(
      0,
      getPosition(thisURL, "/", 2)
    );
    url = thisURLwithParam;
  } else {
    // option = null = just the page name (eg: products.php)
    thisURL = thisURL.substring(
      0,
      thisURL.indexOf("#") == -1 ? thisURL.length : thisURL.indexOf("#")
    );
    thisURL = thisURL.substring(
      0,
      thisURL.indexOf("?") == -1 ? thisURL.length : thisURL.indexOf("?")
    );
    thisURL = thisURL.substring(0, getPosition(thisURL, "/", 2));
    url = thisURL;
  }

  if (url == "/") {
    url = "/home";
  }

  return url;
}

function thisSubURL(params) {
  var url,
    thisSubURL = document.location.pathname;

  if (params === 1) {
    // option = 1 = full page name with all parameters eg: products.php?id_product=1
    thisURLwithParam = thisSubURL.substring(
      0,
      thisSubURL.indexOf("#") == -1
        ? thisSubURL.length
        : thisSubURL.indexOf("#")
    );
    thisURLwithParam = thisURLwithParam.substring(
      0,
      getPosition(thisSubURL, "/", 3)
    );
    url = thisURLwithParam;
  } else {
    // option = null = just the page name (eg: products.php)
    thisSubURL = thisSubURL.substring(
      0,
      thisSubURL.indexOf("#") == -1
        ? thisSubURL.length
        : thisSubURL.indexOf("#")
    );
    thisSubURL = thisSubURL.substring(
      0,
      thisSubURL.indexOf("?") == -1
        ? thisSubURL.length
        : thisSubURL.indexOf("?")
    );
    thisSubURL = thisSubURL.substring(0, getPosition(thisSubURL, "/", 3));
    url = thisSubURL;
  }

  if (url == "/") {
    url = "/home";
  }

  return url;
}

function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

$("select.selectize").each(function () {
  var create = false;
  var delimiter = ",";
  var persist = false;
  var openOnFocus = true;
  var sortField = "value";

  // TODO Change parameters to data-attributes
  if ($(this).hasClass("create")) {
    create = true;
  }

  if ($(this).hasClass("sort-index")) {
    sortField = [
      {
        field: "index",
        direction: "desc",
      },
      {
        field: "$score",
      },
    ];
  }

  if (create) {
    openOnFocus = false;
    var createUrl = $(this).data("create-url");
    create = function (input) {
      $.ajax({
        type: "post",
        url: createUrl,
        async: false,
        data: {
          text: input,
        },
      })
        .done(function (response) {
          console.log(response);

          if (response.status === 201) {
            console.log(response);
            id = response.id;
          } else {
            console.log("ajaxerror", response);
          }
        })
        .fail(function (response) {
          console.log("ajaxerror", response);
        })
        .always(function (response) {
          console.log("ajaxalways", response);
        });
      return {
        value: id,
        text: input,
      };
    };
  }

  $(this).selectize({
    delimiter: delimiter,
    persist: persist,
    create: create,
    sortField: sortField,
    openOnFocus: openOnFocus,
  });
});

// -- Overlay Toggle

$("#toggle").click(function () {
  $("main").css("z-index", 0);
  $(this).toggleClass("active");
  $("#overlay").toggleClass("open");

  if ($(this).hasClass("active")) {
    $("body").css("overflow", "hidden");
    $("#cms-page-description-wrapper").css("z-index", "0");
  } else {
    $("body").css("overflow", "auto");
    $("#cms-page-description-wrapper").css("z-index", "2");
  }
});

// Timeline

$(".timeline-1").Timeline();

// -- Modal

$(".watch-video").on("click", function () {
  $("#video-modal").show();
  $(".overlay").show();
});

$(".close-trailer").on("click", function () {
  let videoModal = $("#video-modal");
  videoModal.find("video").trigger("pause");
  videoModal.find("video").attr("currentTime", 0);
  videoModal.find("video").trigger("load");
  videoModal.hide();
  $(".overlay").hide();
});

$(".modal").on("click", function () {
  e.stopPropagation();
});

// -- Collapsible

$(".collapsible").click(function () {
  let temp = $(this).hasClass("active");
  $(".collapsible").removeClass("active").next().css("max-height", 0);
  if (!temp) {
    $(this).addClass("active");
  }
  let content = $(this).next();
  if ($(this).hasClass("active")) {
    content.css("max-height", content.prop("scrollHeight") + "px");
  } else {
    content.css("max-height", 0);
  }
});

window.initializeFlatPickr = function () {
  // https://github.com/flatpickr/flatpickr
  $("input.flatpickr").each(function () {
    // TODO Add parameters via data-attributes
    var mode = $(this).data("mode") || "single";
    var enableTime = $(this).data("enable-time") || false;
    var noCalendar = $(this).data("no-calendar") || false;
    var dateFormat =
      $(this).data("date-format") || (enableTime ? "Y-m-d H:i:S" : "Y-m-d");
    var altInput = $(this).data("alt-input") || true;
    var altFormat =
      $(this).data("alt-format") ||
      (enableTime ? "l F J, Y - h:i K" : "l F J, Y");
    var monthSelectorType = $(this).data("month-selector-type") || "static";
    var yearSelectorType = $(this).data("year-selector-type") || "static";
    var minDate = $(this).data("min-date") || null;
    var maxDate = $(this).data("max-date") || null;

    flatpickr($(this)[0], {
      mode: mode,
      monthSelectorType: monthSelectorType,
      yearSelectorType: yearSelectorType,
      enableTime: enableTime,
      noCalendar: noCalendar,
      dateFormat: dateFormat,
      altInput: altInput,
      altFormat: altFormat,
      minDate: minDate,
      maxDate: maxDate,
    });
  });
};

$("select.selectize").each(function () {
  var create = false;
  var delimiter = ",";
  var persist = false;
  var openOnFocus = true;
  var sortField = "value";

  // TODO Change parameters to data-attributes
  if ($(this).hasClass("create")) {
    create = true;
  }

  if ($(this).hasClass("sort-index")) {
    sortField = [
      {
        field: "index",
        direction: "desc",
      },
      {
        field: "$score",
      },
    ];
  }

  if (create) {
    openOnFocus = false;
    var createUrl = $(this).data("create-url");
    create = function (input) {
      $.ajax({
        type: "post",
        url: createUrl,
        async: false,
        data: {
          text: input,
        },
      })
        .done(function (response) {
          console.log(response);

          if (response.status === 201) {
            console.log(response);
            id = response.id;
          } else {
            console.log("ajaxerror", response);
          }
        })
        .fail(function (response) {
          console.log("ajaxerror", response);
        })
        .always(function (response) {
          console.log("ajaxalways", response);
        });
      return {
        value: id,
        text: input,
      };
    };
  }

  $(this).selectize({
    delimiter: delimiter,
    persist: persist,
    create: create,
    sortField: sortField,
    openOnFocus: openOnFocus,
  });
});

// Mobile filter and sort pop up
$(document).ready(function ($) {
  //open popup
  $(".popup-trigger").on("click", function (event) {
    event.preventDefault();
    $(".popup").addClass("is-visible");
  });

  //close popup
  $(".popup").on("click", function (event) {
    if ($(event.target).is(".popup-close") || $(event.target).is(".popup")) {
      event.preventDefault();
      $(this).removeClass("is-visible");
    }
  });
  //close popup when clicking the esc keyboard button
  $(document).keyup(function (event) {
    if (event.which == "27") {
      $(".popup").removeClass("is-visible");
    }
  });
});
